import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Footer from "../components/Footer"
import DynamicComponent from "../components/DynamicComponent"
import useStoryblok from "../lib/storyblok"
import { render } from "storyblok-rich-text-react-renderer"

const Article = ({ pageContext, location }) => {
  let story = pageContext.story
  story = useStoryblok(story, location)
  console.log(story.content.description.slice(0, 160))
  console.log("page", pageContext.story.slug)
  return (
    <Layout>
      <Seo
        title={story.content.title}
        description={
          story.content.description.slice(0, 160) || story.content.title
        }
        pageName={`articles/${pageContext.story.slug}`}
      />
      <div className="relative py-16 bg-white overflow-hidden article">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div
            className="relative h-full text-lg mx-auto"
            aria-hidden="true"
          ></div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1 class="sr-only">{story.content.title}</h1>
            <h2>{story.content.title}</h2>
            <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              {story.content.leadingtext}
            </span>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              {render(story.content.content)}
            </p>
          </div>
          <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto"></div>
        </div>
      </div>

      <div className="flex items-center ml-3 mb-6">
        <div>
          <img
            className="h-10 w-10 rounded-full"
            src={story.content.avatar.filename}
            alt={story.content.avatar.alt}
          />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
            {story.content.author}
          </p>
          <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
            Author
          </p>
        </div>
      </div>

      <Footer />
    </Layout>
  )
}

export default Article
